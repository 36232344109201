import { useParseData } from '../../../utils/parse.utils';
import { consola } from 'consola';
import { BannerTypeDto, BannerTypeEntity, BannerItemTypeEntity } from "./banner.type"

export const useBannerUtils = () => {

    const generateBannerDto = (item: BannerTypeEntity): BannerTypeDto | any => {
        try {
            let output = {
                ...item,
                name: item.name,
            }
            if (item.banner_items && Array.isArray(item.banner_items) && item.banner_items.length > 0) {
                output['banner_items'] = item.banner_items.map((ite) =>
                    typeof ite === 'object' ? generateBannerItemDto(ite) : ite
                )
            }

            return output
        } catch (e: any) {
            consola.error('ERR generateBannerDto : ', e)
            return {}
        }
    }

    const generateBannerItemDto = (item: BannerItemTypeEntity): BannerTypeDto | any => {
        try {
            let output = {
                ...item,
            }
            return output
        } catch (e: any) {
            consola.error('ERR generateBannerItemDto: ', e)
            return {}
        }
    }

    return {
        generateBannerDto,
        generateBannerItemDto
    }
}
