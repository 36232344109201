import { Query } from '@directus/types';
import consola from "consola";
import { useBannerUtils } from './useBannerUtils';
import { BannerTypeEntity } from './banner.type';

export const useBanner = () => {

    const { getItems } = useDirectusItems()
    const { generateBannerDto } = useBannerUtils()

    const getBanner = async (options: { name: any }) => {
        return getBannerGroupEntity({
            query: {
                filter: {
                    _and: [
                        {
                            status: {
                                _eq: 'published'
                            }
                        },
                        {
                            name: {
                                _eq: options.name
                            }
                        }
                    ]
                },
                fields: [
                    'id',
                    'status',
                    'name',
                    'banner_items.id',
                    'banner_items.status',
                    'banner_items.image_bg',
                    'banner_items.image_mobile',
                    'banner_items.caption_title',
                    'banner_items.caption_description',
                    'banner_items.caption_subtitle',
                    'banner_items.btn_text',
                    'banner_items.btn_link',
                ]

            }
        })
            .then((data) =>
            ({
                banners: Array.isArray(data)
                    ? generateBannerDto(data[0])
                    : [],

            }))
            .catch((e) => {
                consola.error('ERR getHomeBanner: ', e)
                return {}
            })
    }

    //===============================================
    const getBannerGroupEntity = (options?: {
        id?: string | number
        query?: Query
    }): Promise<Array<BannerTypeEntity> | any> => {
        let data: any = {
            collection: 'banner_groups',
            params: {
                fields: ['*'],
                ...options?.query
            }
        }
        if (options?.query) {
            data.params = {
                ...data.params,
                ...options.query
            }
        }

        return getItems(data)
    }
    return {
        getBanner,
    }
}
